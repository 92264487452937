import React from 'react'
import SecondaryHeader from '../components/header/secondary/secondary-header'
import HeroFeatures from '../components/features/hero-feature/hero-feature'
import AdvancePricing from '../components/pricing/advance-pricing/advance-pricing'
import FaqSection from '../components/faq/faq-section'
import TestimonialSection from '../components/testimonial/testimonial-section'
import Datacenter from '../components/datacenter/datacenter'
import FeatureSection from '../components/features/feature-section/feature-section'
import SEO from '../components/seo/seo'
function AdvancedHosting() {
  const title = 'Advance Hosting'
  const subtitle =
    'We provide every customer with the necessary tools to get a fully-functional Linux Hosting.'

  return (
    <>
      <SEO title={title} />
      <SecondaryHeader title={title} subtitle={subtitle} />
      <HeroFeatures />
      <AdvancePricing />
      <Datacenter />
      <FeatureSection />
      <FaqSection />
      <TestimonialSection />
    </>
  )
}

export default AdvancedHosting
